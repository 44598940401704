<template>
  <BListCard
    :searchActive="true"
    :newAddActive="true"
    :totalActive="true"
    :show="show"
    :title="'Stok Birim İşlemleri'"
    :total="rows.length"
    :type="type"
    @newAdd="handlerNewItem"
    @searchInput="search = $event"
    @handlerClose="type = $event"
  >
    <template v-slot:leftBody>
      <b-alert
        variant="warning"
        show
        class="text-center m-3"
        v-if="!rows.length"
      >
        <h4 class="alert-heading">Bilgi!</h4>
        <p>Henüz Kayıt Bulunmamaktadır.</p>
      </b-alert>

      <draggable
        v-else
        v-model="rows"
        tag="ul"
        class="list-group"
        handle=".draggable-task-handle"
        @end="handlerBirimSiraChange"
      >
        <TeslimatBirimItem
          v-for="(item, index) in rows"
          :key="index"
          :item="item"
          @handlerUpdate="handlerUpdate($event)"
        />
      </draggable>
    </template>
    <template v-slot:rightBody>
      <div class="p-3">
        <b-alert variant="info" show class="text-center" v-if="!type">
          <h4 class="alert-heading">Bilgi!</h4>
          <p>Lütfen Yeni Kayıt Ekleme/Düzenlemek İçin İşlem Seçimi Yapınız.</p>
        </b-alert>
        <TeslimatBirimEkle
          v-else
          :updateData="updateData"
          @show="show = $event"
          @updateRefresh="handlerUpdatedData"
          @handlerClose="type = $event"
        />
      </div>
    </template>
  </BListCard>
</template>

<script>
import BListCard from "@/components/cards/BListCard.vue";
import { ref, watch, defineComponent, computed } from "@vue/composition-api";
import store from "@/store";
import TeslimatBirimEkle from "./component/TeslimatBirimEkle.vue";
import TeslimatBirimItem from "./component/TeslimatBirimItem.vue";
import { useToast } from "vue-toastification/composition";
import draggable from "vuedraggable";
import { langs } from "@/libs/languages";
export default defineComponent({
  components: {
    draggable,
    BListCard,
    TeslimatBirimEkle,
    TeslimatBirimItem,
  },
  setup() {
    const expo = {};
    const toast = useToast();
    expo.search = ref(null);
    expo.type = ref(false);
    expo.show = ref(false);
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.updateData = ref({});
    expo.rows = ref([]);
    expo.form = ref({
      k_no: null,
      baslik: "00:00",
      statu: true,
    });

    const handlerGetData = async () => {
      expo.show.value = true;
      await store.dispatch("teslimatSaatListele");
      expo.rows.value = store.getters.getTeslimatSaatler;
      expo.show.value = false;
    };

    handlerGetData();

    expo.handlerNewItem = () => {
      expo.show.value = true;
      store
        .dispatch("teslimatSaatEkle", expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success("Ekleme Başarılı.", { position: "bottom-left" });
            expo.updateData.value = res.data.data;
            expo.type.value = true;
            expo.show.value = false;
          }
        })
        .catch((err) => {
          if (err.success === false) {
            expo.show.value = false;
            toast.success(err.message, { position: "bottom-left" });
          }
        });
    };

    expo.handlerUpdate = async (event) => {
      expo.show.value = true;
      expo.updateData.value = await event;
      expo.type.value = true;
      expo.show.value = false;
    };

    expo.handlerUpdatedData = () => {
      expo.rows.value = store.getters.getTeslimatSaatler;
    };

    expo.handlerBirimSiraChange = () => {
      store
        .dispatch("teslimatSaatSiraGuncelle", { teslimatSaat: expo.rows.value })
        .then((res) => {
          if (res.data.success == true) {
            toast.success("Sıra güncelleme başarılı.", {
              position: "bottom-left",
            });
          }
        })
        .catch((err) => {
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0
                ? Object.keys(err.data.dup)[0] + " " + err.data.message
                : err.data.message,
              { position: "bottom-left" }
            );
          }
        });
    };

    watch(expo.search, (val) => {
      if (val.length > 0) {
        const search = val.toLocaleLowerCase("TR").trim();
        let filteredData = store.getters.getTeslimatSaatler;
        filteredData = filteredData.filter((rows) => {
          return rows.icerik["tr"].baslik
            .toLocaleLowerCase("TR")
            .includes(search);
        });
        expo.rows.value = filteredData;
      } else {
        expo.rows.value = store.getters.getTeslimatSaatler;
      }
    });

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
